export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Profile",
    route: "profile",
    icon: "UserIcon",
  },
  {
    title: "Job",
    route: "job",
    icon: "BriefcaseIcon",
  },
];
